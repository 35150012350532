/*
Явная приоритезация слоёв и подслоёв без импортов (через строку с перечислением слоёв) необходима в
.css - SASS/SCSS её НЕ поддерживает и формирует приоритезацию через импорты.
Данный файл необязательно импортировать в App.tsx - приложение увидит объявление данного слоя.
Однако рекомендуется его импортировать в App.tsx перед импортом главных стилей, чтобы явно указать приложению порядок
(@layer приоритеты - должны быть раньше всех стилей и слоёв во всём приложении)

Оборачивание в слои стилей:
- Можно не оборачивать в слои явно, если в стилях одного и того же файла нет important-стилей.
Но если есть important-стили - обязательно разбить файл на два слоя: с обычными стилями и с important-стилями.
- important-стили требуют иного указания порядка приоритета слоёв, т.к. реверсируют очередность приоритетов слоёв.
Почему это необходимо:
- Обычные unlayered-стили вставляются автоматически в конец порядка приоритетов (что делает их максимально приоритетными для других слоёв/стилей).
- Но important-unlayered-стили так же вставляются автоматически в конец порядка приоритетов (что делает их минимально приоритетными для других important-слоёв/стилей).
*/

@layer /* important-слои редактора */
editor-important-styles,
/* ui-kit'ы */
primereact, ui-kit-styles, vendors, spacing, primeicons,
/* основной frontend */
weldbook-frontend, weldbook-frontend.weld-palette, weldbook-frontend.main, weldbook-frontend.passport, defect-analysis,
/* обычные слои редактора */
editor-styles;
